"use client";
import { Open_Sans } from "next/font/google";
import { useRouter } from "next/navigation";
import NotFoundIllus from "shared-assets/images/404.webp";
import { ErrorPage } from "shared-ui";

import "@/styles/globals.css";

const openSans = Open_Sans({
  subsets: ["latin"],
  variable: "--font-openSans",
  display: "swap",
});

export default function NotFound() {
  const router = useRouter();

  const handleOnClick = () => {
    router.back();
  };

  return (
    <html className={openSans.variable}>
      <body>
        <ErrorPage
          imageUrl={NotFoundIllus.src}
          onClickPrimaryAction={handleOnClick}
          title="Maaf, Halaman Tidak Ditemukan."
          desc={
            <div>
              Kami tidak dapat menemukan halaman yang sesuai dengan permintaan
              Anda.
              <br /> Ini mungkin disebabkan oleh salah ketik URL, perubahan pada
              halaman, atau halaman yang telah dihapus.
            </div>
          }
        />
      </body>
    </html>
  );
}
